import React from "react";
import { cn } from "../../lib/helpers";
import * as styles from "./psychk.module.css";

const Psychk = ({ active, width = "100px" }) => {
  return (
  <svg
    id="Vrstva_2"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 340 340"
    width={width}
    className={active ? styles.active : ""}
  >
    <g id="Vrstva_1-2">
      <path
        className={cn(styles.cls1, styles.svgElem1)}
        d="m286.79,105.06C256.35-48.93,22.52-26.57,22.83,131.03c0,13.44-2.49,26.17-7.84,38.46-4.92,16.43-28.43,45.05-2.54,54.06,9.32,3.05,19.66,1.94,19.97,14.79,1.31,13.23,4.33,26.27,5.96,39.48,1.51,12.21,9.69,21.76,21.74,23.74,18.08,1.21,36.49.76,54.54-.24,23.39,1.45,51.11-44.73,30.63-50.05-4.21-.13-6.97,2.67-7.2,7.3-.86,17.27-13.53,29.47-30.81,29.55-13.98.07-27.95.04-41.93,0-7.63-.02-12.1-4.23-13.26-11.87-2.42-15.99-5.03-31.96-7.54-47.94-1.12-7.16-5.25-11.64-12.14-13.62-4.78-1.38-9.59-2.65-14.37-4-4.44-1.26-4.67-1.74-2.91-5.93,4.38-10.44,8.74-20.89,13.18-31.3,10.49-21.46,6.5-45.78,11.34-68.45,51.58-182.5,316.22-62.47,213.56,96.81-13.98,19.65-9.88,44.45-10.37,67.14h-.22c0,11.98-.03,23.96.03,35.93-.52,4.87,2.51,10.14,8.01,9.08,3.57-.58,5.7-3.28,5.71-7.21.04-21.21.19-42.42-.03-63.63,3.19-51.69,45.63-62.21,30.45-138.07"
      />
      <path
        className={cn(styles.cls1, styles.svgElem2)}
        d="m238.9,111.86c-2.71-2.39-3.68-4.58-3.62-8.16.39-22.79-17.21-41.44-39.94-42.49-2.54-.12-3.92-1.09-5.35-3.13-10.31-14.68-24.45-22.3-42.47-20.95-20.38,1.53-33.99,12.69-41.68,31.44-.42,1.03-1.92,1.81-3.08,2.3-25.6,2.09-43.01,24.4-38.75,49.81,2.77,17.35,19.1,32.65,36.54,33.96,2.51.19,3.57.9,4.43,3.29,3.87,10.69,11.44,17.55,22.72,19.25,14.28,1.73,35.94-3.35,36.62,17.53,1.46,7.95-4.48,24.8,6.73,26.18,4.32.14,7.18-2.96,7.26-8.13,3.45-35.22-10.16-52.08-46.35-48.92-19.57-1.24-19.92-30.11.96-31.42,4.58-.59,6.93-3.16,6.71-7.33-.21-3.96-3.1-6.33-7.69-6.3-13-.06-24.19,9.6-28.02,22.17-35.35-1.85-34.38-54.98-.88-55.56-.71,12.51,14.23,15.97,14.13,2.16-.39-6.09.44-11.99,3.15-17.45,10.89-25.28,48.68-25.67,60.31-.8,3.02,5.48,4.31,6.28,10.32,5.55,14.5-1.75,29.38,10.72,30.35,25.28.44,6.56-.96,12.54-4.21,18.26-4.57,9.55,9.56,14.34,13.32,4.77,14.99,14.43.82,41.04-19.82,36.9-8.37-.91-22.36-12.91-15.83-21.23,7.95-6.9,11.71-15.33,9.97-25.9-1.16-8.74-14.05-7.58-13.59,1.32,2.95,13.06-14.57,22.39-22.95,11.54-3.25-4.26-8.48-3.82-10.82-1.63-8.43,11.21,12.08,20.55,21.58,20.54,17.55,62.46,103.16,14.05,59.96-32.85"
      />
      <path
        className={cn(styles.cls1, styles.svgElem3)}
        d="m171.94,113.2c7.69.98,8.43-9,7.28-14.75-1.49-10.11-9.91-19.01-19.99-21.4-11.66-2.92-29.28,3.32-29.52,16.34,9.23,15.87,14.33-8.82,27.61-2.35,13.53,4.1,3.37,20.42,14.63,22.15"
      />
    </g>
  </svg>
  );
};

export default Psychk;
