import React from "react";
import { cn } from "../../lib/helpers";
import * as styles from "./derma.module.css";

const Aku = ({ active, width = "100px" }) => {
  return (
    <svg
      version="1.1"
      id="Vrstva_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 78.3 79.1"
      width={width}
      className={active ? styles.active : ""}
    >
      <path
        className={cn(styles.st0, styles.svgElem1)}
        d="M20.8,31.2l-2.2-3.8c-0.5-0.7-1.4-0.9-2.1-0.4c-0.6,0.4-0.8,1.2-0.5,1.9l2.2,3.8c0.3,0.5,0.8,0.7,1.3,0.7
	c0.3,0,0.5-0.1,0.7-0.2C20.9,32.8,21.2,31.9,20.8,31.2z"
      ></path>
      <path
        className={cn(styles.st0, styles.svgElem2)}
        d="M20.2,7.3c-0.7-0.4-1.6-0.2-2,0.5l-6.7,11.6c-0.3,0.5-0.3,1,0,1.5l1.9,3.4c0.3,0.5,0.8,0.7,1.3,0.7
	c0.3,0,0.5-0.1,0.7-0.2c0.7-0.4,0.9-1.3,0.5-2l-1.6-2.6l6.3-10.9C21.1,8.7,20.9,7.8,20.2,7.3C20.2,7.3,20.2,7.3,20.2,7.3z"
      ></path>
      <rect
        x="2.1"
        y="2.1"
        className={cn(styles.st1, styles.svgElem3)}
        width="74.2"
        height="75.1"
      ></rect>
      <g>
        <path
          className={cn(styles.st2, styles.svgElem4)}
          d="M76,36.3l-9.6-16.6c-0.3-0.5-0.8-0.7-1.3-0.7H46.8l-9.2-16c-0.3-0.5-0.8-0.7-1.3-0.7H17.1
		c-0.5,0-1,0.3-1.3,0.7L6.2,19.5c-0.3,0.5-0.3,1,0,1.5l9.6,16.6c0.3,0.5,0.8,0.7,1.3,0.7h18.3l9.2,16c0.3,0.5,0.8,0.7,1.3,0.7h19.2
		c0.5,0,1-0.3,1.3-0.7L76,37.8C76.2,37.3,76.2,36.7,76,36.3z M18,35.3L9.2,20.2L18,5.1h17.5l8.7,15.1l-8.7,15.1H18z M64.2,52.1H46.8
		L38.1,37l8.7-15.1h17.4L73,37L64.2,52.1z"
        ></path>
        <path
          className={cn(styles.st2, styles.svgElem5)}
          d="M43.3,58.2l-9.6-16.6c-0.3-0.5-0.7-0.7-1.3-0.7H13.2c-0.5,0-1,0.3-1.3,0.7L2.3,58.2c-0.3,0.5-0.3,1,0,1.5
		l9.6,16.6c0.3,0.5,0.8,0.7,1.3,0.7h19.2c0.5,0,1-0.3,1.3-0.7l9.6-16.6C43.5,59.2,43.5,58.6,43.3,58.2z M31.5,74H14.1L5.3,58.9
		l8.7-15.1h17.5l8.7,15.1L31.5,74z"
        ></path>
      </g>
    </svg>
  );
};

export default Aku;
