import React from "react";
import { cn } from "../../lib/helpers";
import * as styles from "./vlasy.module.css";

const Vlasy = ({ active, width = "100px" }) => {
  return (
    <svg
      version="1.1"
      id="Vrstva_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 72 72"
      width={width}
      className={active ? styles.active : ""}
    >
      <path
        className={cn(styles.st0, styles.svgElem1)}
        d="M66.2,31.6H49.5c0.3-1.2,0.7-2.3,1.1-3.5c1.6-4.1,3.8-7.9,6.7-11.2l8.9-10.3c0.4-0.5,0.4-1.3-0.1-1.7
      c-0.4-0.4-1-0.4-1.5-0.1l-8,5.2c-6.2,4.1-11.7,9.4-16,15.4c-3.9,5.4-6,12-6,18.7c0,3.7,3,6.8,6.8,6.8c3.7,0,6.8-3,6.8-6.8v-2.9
      c0-2.4,0.2-4.8,0.7-7.2h16v23.3c-1.4,1.4-3.8,1.4-5.2,0c-0.3-0.3-0.6-0.8-0.8-1.2c-1.2-3.1-4.8-4.7-7.9-3.5
      c-0.8,0.3-1.5,0.8-2.1,1.4l-3.3,3.3c-1.4,1.4-3.8,1.5-5.2,0c-0.4-0.3-0.6-0.8-0.8-1.2c-1.2-3.1-4.8-4.7-7.9-3.5
      c-0.8,0.3-1.5,0.8-2.1,1.4l-3.3,3.3c-1.4,1.4-3.7,1.5-5.2,0c-0.4-0.4-0.6-0.8-0.8-1.3c-1.2-3.1-4.8-4.7-7.9-3.5
      c-0.8,0.3-1.5,0.8-2.1,1.4L7,57.2V39.9h9.2c0.7,0,1.2-0.6,1.2-1.2c0-0.7-0.6-1.2-1.2-1.2H7V34h21.4c0.7,0.1,1.3-0.4,1.3-1.1
      c0.1-0.7-0.4-1.3-1.1-1.3c-0.1,0-0.1,0-0.2,0H5.8c-0.7,0-1.2,0.6-1.2,1.2v33.4c0,0.7,0.6,1.2,1.2,1.2h60.4c0.7,0,1.2-0.5,1.2-1.2
      V32.8C67.4,32.1,66.9,31.6,66.2,31.6z M48.4,27.2c-1.7,4.5-2.6,9.2-2.6,14v2.9c0,2.4-1.9,4.3-4.3,4.3c-2.4,0-4.3-1.9-4.3-4.3l0,0
      c0-6.2,1.9-12.2,5.5-17.2C46.9,21,52.1,16,58.1,12l0.5-0.3l-3,3.5C52.5,18.8,50,22.8,48.4,27.2L48.4,27.2z M65,65H7v-4.3l5-5
      c1.4-1.4,3.8-1.4,5.2,0c0.4,0.4,0.6,0.8,0.8,1.2c1.2,3.1,4.8,4.7,7.9,3.5c0.8-0.3,1.5-0.8,2.1-1.4l3.3-3.3c1.4-1.4,3.8-1.4,5.2,0
      c0.4,0.4,0.6,0.8,0.8,1.2c1.2,3.1,4.8,4.7,7.9,3.4c0.8-0.3,1.5-0.8,2.1-1.4l3.3-3.3c1.4-1.4,3.8-1.4,5.2,0c0.4,0.4,0.6,0.8,0.8,1.2
      c1.2,3.1,4.8,4.7,7.9,3.4c0.1,0,0.2-0.1,0.3-0.1L65,65z"
      ></path>
      <path
        className={cn(styles.st0, styles.svgElem2)}
        d="M33.4,34c0.7,0,1.2-0.5,1.2-1.2v0c0-0.3-0.1-0.6-0.4-0.9c-0.2-0.2-0.5-0.4-0.9-0.4c-0.7,0-1.2,0.5-1.2,1.2
      c0,0,0,0,0,0c0,0.3,0.1,0.6,0.4,0.9C32.7,33.9,33.1,34,33.4,34z"
      ></path>
      <path
        className={cn(styles.st0, styles.svgElem3)}
        d="M32.8,57.8c-0.7,0-1.2,0.6-1.2,1.2c0,0.7,0.6,1.2,1.2,1.2c0.7,0,1.2-0.6,1.2-1.2C34,58.4,33.5,57.8,32.8,57.8z"
      ></path>
      <path
        className={cn(styles.st0, styles.svgElem4)}
        d="M53.6,58.7c-0.7,0-1.2,0.6-1.2,1.2c0,0.7,0.6,1.2,1.2,1.2c0.3,0,0.6-0.1,0.9-0.4c0.5-0.5,0.5-1.2,0-1.7
      c0,0,0,0,0,0C54.2,58.8,53.9,58.7,53.6,58.7z"
      ></path>
      <path
        className={cn(styles.st0, styles.svgElem5)}
        d="M36,60.9c-0.7,0-1.2,0.5-1.2,1.2l0,0c0,0.3,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.4,0.9,0.4c0.3,0,0.6-0.1,0.9-0.4
      c0.2-0.2,0.4-0.5,0.4-0.9c0-0.3-0.1-0.6-0.4-0.9C36.6,61.1,36.3,60.9,36,60.9z"
      ></path>
      <path
        className={cn(styles.st0, styles.svgElem6)}
        d="M15.7,60.9c-0.7,0-1.2,0.5-1.2,1.2c0,0,0,0,0,0c0,0.3,0.1,0.6,0.4,0.9c0.2,0.2,0.5,0.4,0.9,0.4
      c0.3,0,0.6-0.1,0.9-0.4c0.2-0.2,0.4-0.5,0.4-0.9c0-0.3-0.1-0.6-0.4-0.9C16.3,61.1,16,60.9,15.7,60.9z"
      ></path>
      <path
        className={cn(styles.st0, styles.svgElem7)}
        d="M20.3,37.8c-0.2,0.2-0.4,0.5-0.4,0.9c0,0.3,0.1,0.6,0.4,0.9c0.2,0.2,0.5,0.4,0.9,0.4c0.7,0,1.2-0.6,1.2-1.2
      c0-0.3-0.1-0.6-0.4-0.9c-0.2-0.2-0.5-0.4-0.9-0.4C20.8,37.5,20.5,37.6,20.3,37.8z"
      ></path>
    </svg>
  );
};

export default Vlasy;
