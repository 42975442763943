import React from "react";
import { cn } from "../../lib/helpers";
import * as styles from "./aku.module.css";

const Aku = ({ active, width = "100px" }) => {
  return (
    <svg
      version="1.1"
      id="Vrstva_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 70.7 70.3"
      width={width}
      className={active ? styles.active : ""}
    >
      <path
        className={cn(styles.st0, styles.svgElem1)}
        d="M35.3,1.4c-8.4,0-16.6,3.2-22.8,8.9c-0.5,0.5-0.5,1.3,0,1.8c0.5,0.5,1.2,0.5,1.7,0.1c2.6-2.4,5.6-4.3,8.9-5.7
      L23,6.5c-6.8,6.8-6.8,17.9,0,24.8c0.7,0.7,1.5,1.4,2.4,2c0.2,0.1,0.5,0.2,0.7,0.2c0.7,0,1.2-0.6,1.2-1.3c0-0.4-0.2-0.8-0.5-1
      c-6.8-4.7-8.6-14-3.9-20.8c2.8-4.1,7.5-6.6,12.5-6.5l0,0c17.3,0,31.3,14,31.3,31.3c0,8.6-3.5,16.8-9.7,22.6c-0.5,0.5-0.5,1.3,0,1.8
      c0.2,0.2,0.6,0.4,0.9,0.4c0.3,0,0.6-0.1,0.9-0.3c13.5-12.9,14-34.2,1.2-47.8C53.4,5.1,44.6,1.4,35.3,1.4z"
      ></path>
      <path
        className={cn(styles.st0, styles.svgElem2)}
        d="M53.6,60.6c-1.9,1.3-3.9,2.5-6,3.3l0.2-0.2c6.8-6.8,6.8-17.9,0-24.8c-3.3-3.3-7.7-5.1-12.4-5.1
      c-1.4,0-2.9-0.2-4.3-0.6c-0.6-0.2-1.4,0.1-1.6,0.7c-0.2,0.6,0.1,1.4,0.7,1.6c0.1,0,0.1,0,0.2,0.1c1.6,0.5,3.3,0.7,5,0.7
      c8.3,0,15,6.7,15,15s-6.7,15-15,15l0,0c-17.3,0-31.3-14-31.3-31.3c0-6.9,2.3-13.6,6.4-19c0.4-0.6,0.3-1.3-0.2-1.8
      c-0.6-0.4-1.3-0.3-1.8,0.2l0,0c-4.5,5.9-7,13.1-6.9,20.5c0,18.7,15.1,33.8,33.8,33.8c7,0,13.9-2.2,19.6-6.3c0.6-0.3,0.9-1.1,0.5-1.7
      c-0.3-0.6-1.1-0.9-1.7-0.5C53.7,60.5,53.6,60.6,53.6,60.6L53.6,60.6z"
      ></path>
      <path
        className={cn(styles.st0, styles.svgElem3)}
        d="M35.3,14.8c0.7,0.1,1.3-0.4,1.4-1.1s-0.4-1.3-1.1-1.4c-0.1,0-0.1,0-0.2,0c-3.6,0-6.5,2.9-6.5,6.6
      s2.9,6.5,6.6,6.5c3.6,0,6.5-2.9,6.5-6.6c0-1.3-0.4-2.6-1.1-3.7c-0.4-0.5-1.2-0.6-1.8-0.2c-0.5,0.4-0.6,1.1-0.3,1.6
      c0.5,0.7,0.7,1.5,0.7,2.3c0,2.2-1.8,4.1-4,4.1c-2.2,0-4.1-1.8-4.1-4C31.3,16.6,33.1,14.8,35.3,14.8C35.3,14.8,35.3,14.8,35.3,14.8z"
      ></path>
      <path
        className={cn(styles.st0, styles.svgElem4)}
        d="M41.9,51.4c0-3.6-2.9-6.5-6.5-6.6c-3.6,0-6.6,2.9-6.6,6.5c0,3.6,2.9,6.6,6.5,6.6c0,0,0,0,0,0
      C39,58,41.9,55,41.9,51.4z M31.3,51.4c0-2.2,1.8-4.1,4.1-4.1c2.2,0,4.1,1.8,4.1,4.1s-1.8,4.1-4.1,4.1c0,0,0,0,0,0
      C33.1,55.5,31.3,53.6,31.3,51.4z"
      ></path>
    </svg>
  );
};

export default Aku;
