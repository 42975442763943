import React from "react";
import { cn } from "../../lib/helpers";
import * as styles from "./dermapen.module.css";

const Dermapen = ({ active, width = "100px" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300 425.75"
      width={width}
      className={active ? styles.active : ""}
    >
      <defs></defs>
      <g id="Vrstva_2" data-name="Vrstva 2">
        <g id="Layer_1" data-name="Layer 1">
          <path
            className={cn(styles.cls1, styles.svgElem1)}
            d="M191.27,25.08l-.27-1L176.24,8.58l0-.06-.13,0-.09-.1-.05.05A131.41,131.41,0,0,0,111,2C97.19,4.17,81.21,9.28,78,15.56L20.79,98.15l-.1.16a149.59,149.59,0,0,0-8.42,15.06l-.14.33-.2.57C0,139.53-2.5,168,4,203.82l33,221.43h70.42l16.59-.11.47-3.44c9.21-68.57,8.22-121.84,7.43-164.64-.64-34.49-1.15-61.74,3.78-85,5.71-26.86,22.8-53.51,39.32-79.28L178,88C193.08,69.75,197.53,48.59,191.27,25.08ZM27.42,102.63,85,19.48l.11-.26c3.09-4,33.3-15.55,69.85-8.4A34.42,34.42,0,0,0,149,17.13l-.12.17c-17.83,26.75-24.3,36.8-34.08,52-6.92,10.76-15.53,24.15-31.08,48l-.13.22C71.55,138.81,60.21,150,34.85,148.08l-.3,0a18.55,18.55,0,0,1-14.64-7.63c-3.84-5.54-4-13.53-.58-23.11.08-.16.14-.32.22-.48l0,0a1.21,1.21,0,0,1,0-.14A143.51,143.51,0,0,1,27.42,102.63ZM102.1,258.35c.87,16.72,1.77,34,2,50.93.37,33.75.37,90.91,0,105.38h-.26c-.27-25.08-1.16-48-2-68.24-.5-12.85-1-25-1.23-36.08-.53-23.64-1.68-43.73-2.69-61.44-2.51-44.17-4.18-73.34,7-104,6-16,30.53-54,46.73-79.14,4.8-7.45,8.94-13.89,11.92-18.67l.11-.18c.87-1.59,3.78-4.8,6.85-5.39a4.08,4.08,0,0,1,1.15-.08L133,104.82l-1.33,21.84-10.31,20.42-14.18,7.21-.53,1.5C98,180.12,100,218.12,102.1,258.35ZM171.73,83.08l-3.51,5.43c-16.91,26.37-34.4,53.64-40.41,81.93-5.13,24.11-4.61,51.76-4,86.77.78,41.81,1.74,93.67-6.91,160l-5,0c.35-4.59.4-15,.43-40.22,0-22.85-.08-50.1-.27-67.81-.19-17.09-1.09-34.46-2-51.25-2-38.57-3.9-75.05,3.59-98L127.31,153l12.23-24.23,1.32-21.51,41.73-68.46-2.7-2.2A13,13,0,0,0,169,33.67c-5.81,1.12-10.5,6.12-12.28,9.27-2.94,4.72-7.05,11.1-11.81,18.49-17.36,27-41.14,63.9-47.5,80.69-11.74,32.25-10,62.08-7.46,107.23,1,17.66,2.14,37.67,2.68,61.17.25,11.17.73,23.33,1.23,36.21.82,20.92,1.74,44.58,2,70.52H43.84l-32-214.67,0-.12c-4.22-23.33-4.45-43.31-.62-61.2a23.93,23.93,0,0,0,2.1,3.72,26.54,26.54,0,0,0,21.06,11.08c2,.15,3.81.22,5.62.22,28.28,0,40.5-17.07,50.51-34.73,15.52-23.77,24.12-37.15,31-47.9,9.75-15.17,16.2-25.2,33.95-51.83,6.34-8.48,10.91-8,16.23-6.37l12,12.66C189.07,48.94,185.12,66.93,171.73,83.08Z"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default Dermapen;
