import React from "react";
import { cn } from "../../lib/helpers";
import * as styles from "./rty.module.css";

const Aku = ({ active, width = "100px" }) => {
  return (
    <svg
      version="1.1"
      id="Vrstva_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 85.8 66.8"
      width={width}
      className={active ? styles.active : ""}
    >
      <path
        className={cn(styles.st0, styles.svgElem1)}
        d="M51.7,35.2c-0.6,0-1.2,0.1-1.8,0.3c-7.9,2.7-16.4,2.5-24.2-0.4c-7.8-2.9-14.3-8.4-18.4-15.6
      c5.1,1.8,10.4,2.7,15.8,2.7c1.5-0.1,3.1,0.1,4.5,0.6c6.7,3.3,14.6,3.3,21.4,0.1c1.7-0.8,3.7-0.7,5.6-0.7c5.2,0,10.3-1,15.2-2.7
      c-2.8,4.3-6.2,8-10.2,11.1c-0.7,0.5-1.4,1.3-1,2c0.5,1,1.9,0.4,2.7-0.3c4.1-3.4,7.7-7.4,10.8-11.7c0.5-0.6,0.9-1.4,0.9-2.2
      c-0.1-1.5-1.6-2.3-3-3C62.5,11.8,56.3,5.1,48,3.4c-2.5-0.5-5.2-0.5-7.2,1.1c-0.5,0.5-1,0.9-1.5,1.3c-0.6,0.4-1.3,0.3-1.8-0.2
      C36.1,3.9,34,3,31.8,3.1c-2.2,0.1-4.3,0.6-6.3,1.4c-0.5,0.1-1,0.4-1.4,0.7c-0.4,0.4-0.5,1-0.2,1.5C24.2,7.2,25,7.1,25.6,7
      c1.6-0.3,3.1-1,4.7-1.3c1.6-0.4,3.3-0.2,4.7,0.7c1.1,0.8,1.8,2.2,3.2,2.4c1.6,0.2,2.7-1.4,4-2.3c2.2-1.5,5.2-1.1,7.7-0.2
      c3.9,1.4,7.3,3.7,10.7,6l7.8,5.3c-4.6,1.2-9.3,2-14,2.3c-4.7,0.3-9.5-0.6-13.8-2.6c-1-0.5-2.1-1-3-0.5c-4.4,2.3-9.3,3.4-14.2,3.2
      c-4.9-0.2-9.8-1-14.5-2.4l11.8-7.9c0.6-0.4,1.4-1.1,1.2-1.8c-0.2-0.6-0.9-1-1.5-0.8C19.6,7.3,19,7.6,18.6,8c-3.8,3-7.9,5.6-12.1,7.8
      c-1,0.5-2.2,1.2-2.4,2.3c0,1,0.3,2,1,2.7c3.9,5.2,8.2,10.3,13.8,13.7c9.5,5.9,21.6,6.3,32.4,3.3c0.8-0.2,1.8-0.7,1.8-1.6
      C52.9,35.7,52.3,35.2,51.7,35.2z M37.2,19.7c0.4-0.3,0.8-0.4,1.3-0.4c0.4,0,0.7,0.2,1,0.4c2,1.1,4.2,1.8,6.4,2.1
      c-4.9,1.5-10.1,1.5-15,0C33.2,21.4,35.3,20.7,37.2,19.7L37.2,19.7z"
      ></path>
      <path
        className={cn(styles.st0, styles.svgElem2)}
        d="M81,53.5L70.8,41.3c-0.4-0.5-0.9-1-1.5-1.3c-0.9-0.4-1.9-0.2-2.6,0.4c-0.7-1.2-1.8-2.2-3-2.8
      c-0.5-0.2-0.9-0.4-1.4-0.7c-0.5-0.5-1-1.1-1.5-1.7c-0.5-0.6-1.4-0.7-2-0.4c-0.5,0.4-1.3-0.1-1.7-0.7l-2.9-3.5
      c-0.3-0.4-0.6-0.7-1.1-1c-0.5-0.4-1.2-0.3-1.5,0.2c-0.2,0.3-0.3,0.8-0.1,1.1c0.2,0.4,0.4,0.8,0.7,1.1l3.9,4.5
      c-0.9,0.7-1.1,2-0.5,2.9c0.4,0.6,0.9,0.9,1.3,1.5c0.3,0.5,0.4,1,0.5,1.6c0.4,1.4,1.2,2.6,2.2,3.6c-0.7,0.5-1,1.3-0.9,2.1
      c0.2,0.8,0.6,1.5,1.2,2.1l10.2,12.1c0.3,0.4,0.6,0.7,1,0.9c0.5,0.3,1.2,0.2,1.5-0.3c0.2-0.3,0.3-0.7,0.1-1.1
      c-0.2-0.5-0.5-0.9-0.8-1.2c-0.6-0.7-1.2-1.3-1.8-2c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.2,0.1-0.3,0.2-0.4c0.5-0.5,1.1-0.8,1.6-1.3
      c0.6-0.3,0.8-1.1,0.5-1.7c-0.3-0.6-1.1-0.8-1.7-0.5c-0.2,0.1-0.3,0.2-0.4,0.4c-0.6,0.4-1.1,1.1-1.7,1.4c-0.1,0.1-0.2,0.1-0.2,0.1
      c-0.1,0-0.2-0.1-0.3-0.2l-1.4-1.6c-0.3-0.3-0.5-0.6-0.6-0.9c-0.1-0.3,0-0.7,0.3-0.9c0.6-0.2,1.2-0.7,1.6-1.2
      c0.4-0.6,0.3-1.3-0.2-1.8c-0.6-0.3-1.3-0.2-1.8,0.3c-0.5,0.5-1,0.9-1.5,1.3c-0.1,0.1-0.2,0.1-0.4,0.1c-0.1,0-0.3-0.1-0.4-0.3
      c-0.6-0.7-1.3-1.5-1.9-2.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.2-0.4,0.3-0.5l6.1-5.1c0.3-0.2,0.7-0.4,0.9-0.2
      c3.4,4.1,6.9,8.2,10.4,12.4c0.3,0.4,0.6,0.7,1.1,1c0.4,0.2,1,0.2,1.4-0.1C82.1,55.2,81.6,54.2,81,53.5z M64.4,42.4l-2.9,2.4
      c-1.1-0.9-1.8-2.1-2-3.5c-0.3-1.1-0.9-2-1.7-2.7c0.2-0.2,0.5-0.5,0.7-0.7c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.5,0,0.6,0.3
      c0.3,0.7,0.8,1.2,1.5,1.5c0.7,0.3,1.3,0.6,2,1c0.5,0.4,0.9,0.8,1.2,1.3c0.1,0.1,0.2,0.2,0.2,0.4C64.7,42,64.6,42.2,64.4,42.4z"
      ></path>
    </svg>
  );
};

export default Aku;
