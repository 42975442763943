import React from "react";
import { cn } from "../../lib/helpers";
import * as styles from "./hubnuti.module.css";

const Aku = ({ active, width = "100px" }) => {
  return (
    <svg
      version="1.1"
      id="Vrstva_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 85.3 76.5"
      width={width}
      className={active ? styles.active : ""}
    >
      <path
        className={cn(styles.st0, styles.svgElem1)}
        d="M55.7,15c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6c0,0,0,0,0,0
      C54.2,14.3,54.9,15,55.7,15z"
      ></path>
      <path
        className={cn(styles.st0, styles.svgElem2)}
        d="M62.9,4.1c0-0.9-0.6-1.6-1.5-1.7c0,0-0.1,0-0.1,0H25c-0.4,0-0.8,0.2-1.1,0.5c-0.3,0.3-0.4,0.7-0.4,1.2
      c1,17.5-6.8,27.6-8.6,34C11.6,49.5,17,64.3,24.5,74c0.3,0.4,0.8,0.6,1.2,0.6h35c0.5,0,0.9-0.2,1.2-0.6c7.1-9.2,12.7-24.6,9.5-35.9
      C69.7,31.7,61.9,21.7,62.9,4.1z M22.5,28.2c2.9-7.2,4.3-14.9,4.2-22.6h33c-0.2,7.7,1.3,15.4,4.2,22.6l0.4,1
      c-6.6,3.1-13.7,4.7-21,4.7c-7.3,0-14.4-1.6-21-4.7C22.3,28.8,22.4,28.5,22.5,28.2z M41.6,71.5H26.5C18.4,60.7,16,48.4,17.5,40.6
      c8.5,3.3,16.5,10.3,24.1,16V71.5z M18.4,37.5c0.6-1.5,1.2-2.9,1.9-4.3l0.6-1.2c7,3.3,14.6,5,22.3,5c7.7,0,15.3-1.7,22.3-5l0.6,1.2
      c0.7,1.4,1.4,2.9,1.9,4.4c-9,3.4-17.4,10.7-24.8,16.3C35.8,48.2,27.3,40.9,18.4,37.5L18.4,37.5z M59.9,71.5H44.8V56.6
      c7.6-5.8,15.6-12.7,24.1-16C70.7,50.5,65.8,63.5,59.9,71.5z"
      ></path>
      <path
        className={cn(styles.st0, styles.svgElem3)}
        d="M56,18.1c-0.8,0.2-1.4,1-1.2,1.9c0.5,2.2,1.1,4.5,1.8,6.6c0.3,0.8,1.2,1.3,2,1s1.3-1.2,1-2
      c-0.7-2.1-1.3-4.2-1.7-6.3C57.7,18.5,56.8,17.9,56,18.1z"
      ></path>
      <path
        className={cn(styles.st0, styles.svgElem4)}
        d="M81.8,15h-9.7l3.6-3.6c0.6-0.7,0.5-1.6-0.1-2.2c-0.6-0.5-1.5-0.5-2.1,0l-6.3,6.2c-0.6,0.6-0.6,1.6,0,2.2
      c0,0,0,0,0,0l6.3,6.3c0.6,0.7,1.6,0.7,2.2,0.1c0.7-0.6,0.7-1.6,0.1-2.2c0-0.1-0.1-0.1-0.2-0.2l-3.6-3.6h9.7c0.9,0,1.6-0.7,1.6-1.6
      C83.4,15.7,82.7,15,81.8,15L81.8,15z"
      ></path>
      <path
        className={cn(styles.st0, styles.svgElem5)}
        d="M19.2,15.4l-6.3-6.3c-0.6-0.6-1.6-0.6-2.2,0s-0.6,1.6,0,2.2l3.6,3.6H4.6C3.7,15,3,15.7,3,16.6
      c0,0.9,0.7,1.6,1.6,1.6h9.7l-3.6,3.6c-0.7,0.5-0.8,1.5-0.3,2.2c0.5,0.7,1.5,0.8,2.2,0.3c0.1-0.1,0.2-0.2,0.3-0.3l6.3-6.3
      C19.8,17,19.8,16,19.2,15.4C19.2,15.4,19.2,15.4,19.2,15.4z"
      ></path>
    </svg>
  );
};

export default Aku;
