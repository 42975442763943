// extracted by mini-css-extract-plugin
export var st0 = "laser-module--st0--2LiwA";
export var svgElem1 = "laser-module--svg-elem-1--3akwC";
export var active = "laser-module--active--1Y6ui";
export var svgElem2 = "laser-module--svg-elem-2--3dY3D";
export var svgElem3 = "laser-module--svg-elem-3--3sjma";
export var svgElem4 = "laser-module--svg-elem-4--LVimL";
export var svgElem5 = "laser-module--svg-elem-5--bxn4K";
export var svgElem6 = "laser-module--svg-elem-6--2I9Z9";
export var svgElem7 = "laser-module--svg-elem-7--3oQd7";
export var svgElem8 = "laser-module--svg-elem-8--2Aqmi";