import React from "react";
import { cn } from "../../lib/helpers";
import * as styles from "./oci.module.css";

const Aku = ({ active, width = "100px" }) => {
  return (
    <svg
      version="1.1"
      id="Vrstva_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 81 59.9"
      width={width}
      className={active ? styles.active : ""}
    >
      <path
        className={cn(styles.st0, styles.svgElem1)}
        d="M78,28L66.1,10.6C61,3.1,51.4,0.1,42.9,3.3L32.1,7.4c-0.8,0.3-1.2,1.2-0.9,1.9s1.2,1.2,1.9,0.9L44,6.1
	c7.2-2.8,15.4-0.2,19.8,6.2L71,23L58,13.8c-2.7-1.9-6.3-2.4-9.4-1.4L9.3,25.8c-1.4,0.5-2.9-0.3-3.3-1.6c-0.6-1.7,0.3-3.6,2-4.3
	l13.5-5.2c0.8-0.3,1.2-1.2,0.9-1.9c-0.3-0.8-1.2-1.2-1.9-0.9l0,0L6.8,17.1c-3.2,1.2-4.9,4.8-3.7,8c1,2.9,4.2,4.4,7.1,3.4l39.3-13.4
	c2.3-0.8,4.8-0.4,6.8,0.9L76,30c0.2,0.2,0.5,0.3,0.8,0.3c0.8,0,1.5-0.7,1.5-1.5C78.3,28.5,78.2,28.2,78,28z"
      ></path>
      <path
        className={cn(styles.st0, styles.svgElem2)}
        d="M27.1,12.4c0.8,0,1.5-0.7,1.5-1.5c-0.1-0.8-0.8-1.4-1.6-1.4c-0.7,0.1-1.3,0.6-1.4,1.4
	C25.7,11.8,26.3,12.4,27.1,12.4z"
      ></path>
      <path
        className={cn(styles.st0, styles.svgElem3)}
        d="M66.4,42.1L66.4,42.1C64.6,41.6,63,40.5,62,39c1.1-0.9,2.2-1.9,3.2-2.9c0.5-0.6,0.5-1.5-0.1-2.1
	c-0.6-0.5-1.4-0.5-1.9,0c-5.7,5.7-13.2,9.4-21.2,10.4h-0.2c-1.5,0.2-2.9,0.3-4.4,0.3l0,0c-1.5,0-2.9-0.1-4.4-0.3h-0.2
	c-8-1-15.5-4.6-21.2-10.4c-0.6-0.6-1.5-0.6-2.1,0c-0.6,0.6-0.6,1.5,0,2.1c1,1,2.1,2,3.2,2.9c-1.1,1.5-2.6,2.6-4.4,3.1H8.3
	c-0.8,0.2-1.2,1-1,1.8C7.5,44.6,8,45,8.7,45c0.1,0,0.3,0,0.4-0.1h0.1c2.4-0.7,4.4-2.2,5.9-4.2c0.5,0.3,1,0.7,1.5,1
	c-0.7,2.3-2.4,4.1-4.7,5l-0.5,0.2c-0.7,0.4-1,1.2-0.7,2c0.2,0.5,0.7,0.8,1.2,0.8c0.2,0,0.4,0,0.5-0.1l0.5-0.2
	c2.8-1.1,5.1-3.4,6.2-6.2c0.6,0.3,1.1,0.6,1.7,0.8c-0.2,2.5-1.6,4.8-3.9,6h-0.1c-0.7,0.4-1,1.3-0.6,2c0.4,0.7,1.3,1,2,0.6h0.1
	c2.9-1.5,4.9-4.3,5.4-7.5c0.6,0.2,1.3,0.4,1.9,0.6c-0.1,2.3-1,4.6-2.6,6.3c-0.5,0.6-0.5,1.5,0.1,2.1c0.3,0.2,0.6,0.4,1,0.4
	c0.4,0,0.8-0.2,1.1-0.5c1.9-2.1,3.1-4.7,3.3-7.5c0.8,0.2,1.7,0.4,2.6,0.5c-0.2,2.3-0.9,4.5-2,6.4l-0.2,0.3c-0.4,0.7-0.2,1.6,0.5,2
	c0.2,0.1,0.5,0.2,0.7,0.2c0.5,0,1-0.3,1.3-0.7l0.2-0.3c1.4-2.3,2.2-4.9,2.4-7.6c0.6,0.1,1.2,0.1,1.8,0.1v8.8
	c0.1,0.8,0.8,1.4,1.6,1.4c0.7-0.1,1.3-0.6,1.4-1.4v-8.8c0.6,0,1.2-0.1,1.8-0.1c0.2,2.7,1.1,5.3,2.4,7.6l0.2,0.3
	c0.3,0.5,0.7,0.7,1.3,0.7c0.3,0,0.5-0.1,0.7-0.2c0.7-0.4,0.9-1.3,0.5-2c0,0,0,0,0,0l-0.2-0.3c-1.2-2-1.8-4.2-2-6.4
	c0.9-0.1,1.7-0.3,2.6-0.5c0.2,2.8,1.4,5.4,3.3,7.5c0.6,0.6,1.5,0.6,2.1,0.1s0.6-1.5,0.1-2.1c-1.6-1.7-2.5-3.9-2.6-6.2
	c0.6-0.2,1.2-0.4,1.9-0.6c0.5,3.2,2.5,6,5.4,7.5h0.1c0.2,0.1,0.4,0.2,0.7,0.2c0.8,0,1.5-0.6,1.5-1.4c0-0.6-0.3-1.1-0.8-1.4h-0.1
	c-2.3-1.2-3.7-3.4-3.9-6c0.6-0.3,1.2-0.5,1.7-0.8c1.1,2.9,3.3,5.1,6.2,6.2l0.5,0.2c0.2,0.1,0.4,0.1,0.5,0.1c0.8,0,1.4-0.7,1.4-1.6
	c0-0.5-0.4-1-0.8-1.2l-0.5-0.2c-2.2-0.9-3.9-2.7-4.7-5c0.5-0.3,1-0.7,1.5-1c1.5,2,3.6,3.4,5.9,4.1h0.1c0.1,0,0.3,0.1,0.4,0.1
	c0.8,0,1.5-0.7,1.5-1.5C67.4,42.8,67,42.3,66.4,42.1L66.4,42.1z"
      ></path>
    </svg>
  );
};

export default Aku;
