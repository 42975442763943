import React from "react";
import { cn } from "../../lib/helpers";
import * as styles from "./bio.module.css";

const Bio = ({ active, width = "100px" }) => {
  return (
    <svg
      version="1.1"
      id="Vrstva_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 72 72"
      width={width}
      className={active ? styles.active : ""}
    >
    <g>
      <path className={cn(styles.st0, styles.svgElem1)} d="M49.9,36h11.3c0.4,0,0.7,0.3,0.7,0.7l0,22.2c0,0.2-0.1,0.4-0.3,0.6c-1.4,1.2-3.6,1.1-4.9-0.2
        c-0.3-0.3-0.6-0.8-0.8-1.2c-1.2-3.1-4.8-4.7-7.9-3.5c-0.8,0.3-1.5,0.8-2.1,1.4l-3.3,3.3c-1.4,1.4-3.8,1.5-5.2,0
        c-0.4-0.3-0.6-0.8-0.8-1.2c-1.2-3.1-4.8-4.7-7.9-3.5c-0.8,0.3-1.5,0.8-2.1,1.4l-3.3,3.3c-1.4,1.4-3.7,1.5-5.2,0
        c-0.4-0.4-0.6-0.8-0.8-1.3c-1.2-3.1-4.8-4.7-7.9-3.5c-0.8,0.3-1.5,0.8-2.1,1.4l-2,2C4.8,58.4,4,58.1,4,57.4V42.7
        c0-0.4,0.3-0.7,0.7-0.7h8.5c0.7,0,1.2-0.6,1.2-1.2c0-0.7-0.6-1.2-1.2-1.2H4.7c-0.4,0-0.7-0.3-0.7-0.7v-2C4,36.3,4.3,36,4.7,36h20.7
        c0.7,0.1,1.3-0.4,1.3-1.1c0.1-0.7-0.4-1.3-1.1-1.3c-0.1,0-0.1,0-0.2,0H2.8c-0.7,0-1.2,0.6-1.2,1.2v33.4c0,0.7,0.6,1.2,1.2,1.2h59.7
        c1.1,0,1.9-0.9,1.9-1.9V35.6c0-1.1-0.9-1.9-1.9-1.9H49.9c-0.7,0-1.2,0.5-1.2,1.2v0C48.7,35.5,49.2,36,49.9,36z M47.7,25.8
        L47.7,25.8c0.6-1.1,1.2-2.1,1.8-3.2c2.5-3.7,5.4-6.9,9-9.5L69.4,5c0.5-0.4,0.7-1.2,0.3-1.7c-0.3-0.5-0.9-0.6-1.4-0.4l-8.9,3.3
        c-6.9,2.7-13.5,6.6-19,11.5c-5,4.4-8.5,10.4-9.9,16.9c-0.8,3.6,1.4,7.3,5.2,8.1c3.6,0.8,7.3-1.4,8.1-5.2l0.6-2.8
        c0.5-2.3,1.2-4.6,2.3-6.9l0,0L47.7,25.8z M47.6,21.3c-2.6,4-4.5,8.4-5.6,13.1l-0.6,2.8c-0.5,2.3-2.8,3.8-5.1,3.3
        c-2.3-0.5-3.8-2.8-3.3-5.1l0,0c1.4-6.1,4.5-11.5,9.1-15.6c5.4-4.8,11.5-8.6,18.3-11.2l0.6-0.2l-3.7,2.8
        C53.4,14,50.1,17.3,47.6,21.3L47.6,21.3z M62,67H4v-4.3l5-5c1.4-1.4,3.8-1.4,5.2,0c0.4,0.4,0.6,0.8,0.8,1.2
        c1.2,3.1,4.8,4.7,7.9,3.5c0.8-0.3,1.5-0.8,2.1-1.4l3.3-3.3c1.4-1.4,3.8-1.4,5.2,0c0.4,0.4,0.6,0.8,0.8,1.2c1.2,3.1,4.8,4.7,7.9,3.4
        c0.8-0.3,1.5-0.8,2.1-1.4l3.3-3.3c1.4-1.4,3.8-1.4,5.2,0c0.4,0.4,0.6,0.8,0.8,1.2c1.2,3.1,4.8,4.7,7.9,3.4c0.1,0,0.2-0.1,0.3-0.1
        L62,67z"></path>
      <path className={cn(styles.st0, styles.svgElem2)} d="M30.2,59.6c-0.7,0-1.2,0.6-1.2,1.2c0,0.7,0.6,1.2,1.2,1.2c0.7,0,1.2-0.6,1.2-1.2
        C31.4,60.2,30.9,59.6,30.2,59.6z"></path>
      <path className={cn(styles.st0, styles.svgElem3)} d="M51,60.5c-0.7,0-1.2,0.6-1.2,1.2c0,0.7,0.6,1.2,1.2,1.2c0.3,0,0.6-0.1,0.9-0.4c0.5-0.5,0.5-1.2,0-1.7l0,0
        C51.6,60.6,51.3,60.5,51,60.5z"></path>
      <path className={cn(styles.st0, styles.svgElem4)} d="M33.4,62.7c-0.7,0-1.2,0.5-1.2,1.2l0,0c0,0.3,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.4,0.9,0.4c0.3,0,0.6-0.1,0.9-0.4
        c0.2-0.2,0.4-0.5,0.4-0.9c0-0.3-0.1-0.6-0.4-0.9C34,62.9,33.7,62.7,33.4,62.7z"></path>
      <path className={cn(styles.st0, styles.svgElem5)} d="M13.1,62.7c-0.7,0-1.2,0.5-1.2,1.2l0,0c0,0.3,0.1,0.6,0.4,0.9c0.2,0.2,0.5,0.4,0.9,0.4c0.3,0,0.6-0.1,0.9-0.4
        c0.2-0.2,0.4-0.5,0.4-0.9c0-0.3-0.1-0.6-0.4-0.9C13.7,62.9,13.4,62.7,13.1,62.7z"></path>
      <path className={cn(styles.st0, styles.svgElem6)} d="M54.8,29.4l4.4,0.1c0.4,0,0.8-0.3,0.8-0.7S59.7,28,59.3,28l-2.5-0.1l10.8-10.1c0.3-0.3,0.3-0.8,0-1.2
        c-0.3-0.3-0.8-0.3-1.1,0L55.6,26.7l0.1-2.5c0.1-0.4-0.2-0.8-0.6-0.9c-0.4-0.1-0.8,0.2-0.9,0.6c0,0.1,0,0.1,0,0.2l-0.1,4.4
        C54.1,29,54.4,29.4,54.8,29.4L54.8,29.4z"></path>
    </g>
    </svg>    
  );
};

export default Bio;
