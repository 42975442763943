import React from "react";
import { cn } from "../../lib/helpers";
import * as styles from "./chiro.module.css";

const Aku = ({ active, width = "100px" }) => {
  return (
    <svg
      version="1.1"
      id="Vrstva_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 77.9 83"
      width={width}
      className={active ? styles.active : ""}
    >
      <path
        className={cn(styles.st0, styles.svgElem1)}
        d="M59.9,67.1c-0.5-0.3-1.1-0.2-1.5,0.1c-0.6,0.6-0.1,1.6,0.3,2.3c1.4,2.4,1.9,5.2,1.5,8c0,0.1-0.1,0.3-0.2,0.4
      C60,78,59.9,78,59.7,78H20c0-3.4,0.1-7,2.4-9.6s5.6-3,8.8-3.6c1.1-0.2,2.4-0.5,2.9-1.6c0.2-0.5,0.3-1.1,0.2-1.7c0-1.2,0-2.3,0-3.5
      c3.7,1.5,7.9,1.5,11.6,0c0,1.2,0,2.4,0,3.6c-0.1,0.6,0.1,1.1,0.3,1.7c0.5,0.5,1.1,0.9,1.8,1l6.3,1.7c0.9,0.2,2,0.3,2.4-0.5
      c0.2-0.5,0-1.1-0.5-1.4c-0.4-0.3-0.9-0.5-1.5-0.6l-5.4-1.4c-0.3,0-0.6-0.2-0.8-0.4c-0.2-0.3-0.3-0.6-0.2-0.9l-0.1-2.8
      c0-0.3,0-0.6,0.1-0.9c0.2-0.4,0.5-0.7,0.9-0.9c2.6-1.7,4.9-3.9,6.5-6.6c0.4-0.7,0.8-1.7,0.2-2.2c-0.5-0.4-1.2-0.4-1.7,0
      c-0.5,0.4-0.9,0.9-1.1,1.5c-2.7,4.7-7.6,7.5-13,7.6c-5.4-0.1-10.3-2.9-13-7.5c-0.2-0.5-0.6-1-1-1.4c-0.4-0.4-1.1-0.5-1.6-0.2
      c-0.8,0.5-0.4,1.8,0.1,2.6c1.7,2.6,4,4.7,6.6,6.4c0.4,0.3,0.5,0.9,0.5,1.4v3.2c0,0.4-0.1,1-0.5,1.1c-4.1,0.8-8.6,1.8-11.2,5
      c-2.5,3.1-2.6,7.4-2.7,11.3c-0.1,0.6,0.1,1.1,0.4,1.6c0.4,0.3,0.8,0.4,1.3,0.3h42c0.8,0.2,1.6-0.3,1.7-1c0.1-0.3,0.1-0.5,0-0.8
      c-0.1-3.6-0.2-7.2-2-10.3C60.7,67.8,60.3,67.4,59.9,67.1z"
      ></path>
      <path
        className={cn(styles.st0, styles.svgElem2)}
        d="M75.1,22.5c0-1-0.4-2.4-1.4-2.3s-1.1,1.2-1.1,2.1c0,6.8,0,13.6,0,20.5c0.1,2.2-0.1,4.3-0.8,6.4
      c-0.9,1.9-2.3,3.6-4,4.8c-3.1,2.5-6.7,4.2-10.5,5.1c-0.6,0.2-1.2,0.2-1.8-0.1c-0.5-0.3-0.8-1.2-0.2-1.5c2.6-1.6,4.9-3.4,7-5.6
      c2.1-2.2,3.2-5.1,3.2-8.2c0-0.9-0.3-1.8-0.9-2.6c-0.6-0.7-1.6-1-2.5-0.7l-5.2,2.4c-0.8,0.4-2,0.5-2.2-0.3c-0.1-0.5,0.3-0.9,0.7-1.3
      c2.7-2.3,5.9-4.7,9.4-3.9c1.2,0.3,1.7-1.4,1.7-2.6c0-3.8,0-7.6,0-11.4c0-0.6-0.1-1.2-0.3-1.8c-0.3-0.6-0.9-0.9-1.5-0.7
      c-0.7,0.3-0.8,1.2-0.8,2c0.1,3.3,0.1,6.7,0.1,10c0.1,0.6,0,1.2-0.3,1.7c-0.3,0.5-0.9,0.6-1.4,0.4c-0.1,0-0.1-0.1-0.2-0.1
      c1.1-3,0.8-6.3-0.9-9c-1.2-2-1.2-4.5-1.8-6.7c-0.7-2.5-2.3-4.6-4.4-6c-1.5-1-3.3-1.8-3.8-3.4c-1.5-4.8-7-7.6-12-7.2s-9.6,3.3-13.1,7
      c-0.5,0.3-0.7,0.9-0.7,1.5c0.2,0.7,1,1,1.6,0.8c0,0,0,0,0,0c0.6-0.3,1.2-0.7,1.6-1.2c2.1-2.3,4.7-4,7.6-5c3-1,6.2-0.8,9,0.5
      c-2.9,0.4-5.5,1.6-7.8,3.5c-0.6,0.4-1.1,1-1.3,1.8c0,0.8,1,1.5,1.6,1c1.7-1.5,3.5-3,5.7-3.6c2.2-0.6,4.9,0.2,5.8,2.3
      c0.7,1.7,0.1,3.6-0.8,5.2c-2.8,4.7-8,7.4-12.9,9.9s-10,5.5-12.3,10.5c-1.2,0-2.4-0.7-2.9-1.9c-0.5-1.1-0.7-2.3-0.6-3.5
      c-0.1-5.4,1.3-10.7,4.1-15.3c0.6-0.9,1.1-2.3,0.1-2.8c-0.8-0.4-1.7,0.4-2.1,1.2c-3.4,5.7-4.9,12.4-4.3,19c0.1,0.4,0,0.8-0.1,1.1
      c-0.2,0.3-0.7,0.5-1,0.2V22.6c0-0.6-0.1-1.4-0.6-1.7c-0.6-0.2-1.2,0-1.5,0.6c-0.3,0.6-0.4,1.2-0.3,1.8c0,3.7,0,7.4,0,11.2
      c-0.1,0.7,0,1.4,0.3,2c0.3,0.7,0.9,1.1,1.6,1c3.3-0.8,6.4,1.5,9,3.7c0.6,0.5,1.2,1.3,0.7,1.9s-1.1,0.2-1.7,0L19.4,41
      c-0.6-0.3-1.3-0.5-2-0.5c-1.6,0.1-2.4,2-2.5,3.6c0.1,3,1.3,5.8,3.3,8c2.1,2.1,4.4,4,6.9,5.5c0.5,0.3,0.3,1.1-0.2,1.3
      c-0.5,0.2-1,0.3-1.6,0.1c-4.4-0.7-8.5-2.8-11.7-5.8c-1.4-1.2-2.4-2.7-3.2-4.4c-0.7-2.3-1-4.6-0.9-7c0-6.4,0.1-12.9,0.1-19.3
      c0-1-0.3-2.4-1.4-2.4s-1.2,1.3-1.2,2.3l0.1,19.9c-0.1,2.1,0.1,4.2,0.6,6.3c1,3.2,3,5.9,5.7,7.8c2.7,1.9,5.6,3.4,8.8,4.4
      c1.5,0.6,3.1,0.9,4.7,0.7c1.6-0.2,2.9-1.6,3-3.2c-0.1-1.7-1.7-2.8-3.1-3.8c-2.5-1.8-4.7-4-6.5-6.5c-0.7-0.9-1.1-2-1-3.2
      c0.2-1.1,1.7-2,2.6-1.2c1.1,1.1,2.6,1.8,4.1,2c1.6,0.2,3.2-0.6,4-1.9c0.8-1.4,0.2-3.2-1.2-4c0,0,0,0,0,0c-0.9-0.5-1.3-1.6-1-2.5
      c0.3-0.9,0.9-1.8,1.7-2.4c4.5-4.2,10.1-7,15.6-9.7c1.2-0.6,2.5,0,3.6,0.7c4.1,2.6,7.1,6.5,8.5,11.2c0.2,0.6,0.3,1.4-0.2,1.8
      c-1.4,1-3,2.3-2.9,4c0,1.9,2.2,3.2,4.1,3.1s3.6-1.2,5.1-2.3c0.6-0.3,1.2-0.1,1.5,0.5c0.3,0.5,0.3,1.1,0.2,1.7
      c-0.7,4.4-5.3,6.9-8.6,9.9c-1,0.7-1.6,1.8-1.8,3c0.1,1.6,1.3,2.9,2.9,3.1c1.5,0.2,3.1,0,4.5-0.6c3.3-1,6.4-2.5,9.1-4.5
      c2.8-2,4.8-4.9,5.7-8.3c0.4-1.8,0.5-3.7,0.4-5.6V22.5z M46.6,22.9c-0.3-0.1-0.6-0.5-0.3-0.6c1.3-1.1,2.5-2.3,3.5-3.6
      c1.1-1.3,1.7-2.9,2-4.6c0-0.2,0.3-0.2,0.5-0.1c2.9,1.7,4.8,4.6,5.3,7.9c0.1,0.3,0,0.6-0.1,0.9c-0.5-0.4-1.3-0.3-1.7,0.2
      c-0.3,0.4-0.3,0.8-0.1,1.3c0.2,0.3,0.4,0.5,0.7,0.7c0.7,0.5,1.6,0.8,2.3,1.4c1.1,1,1.8,2.5,1.8,4c0,1.5-0.2,3-0.6,4.5
      c-0.2,0.8-1.5,0.9-2,1.6l-0.9-2.3c-0.2-0.5-0.4-1-0.7-1.5C54.2,28.4,50.8,25,46.6,22.9z"
      ></path>
    </svg>
  );
};

export default Aku;
