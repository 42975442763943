import React from "react";
import { cn } from "../../lib/helpers";
import * as styles from "./laser.module.css";

const Aku = ({ active, width = "100px" }) => {
  return (
    <svg
      version="1.1"
      id="Vrstva_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 64.7 76.7"
      width={width}
      className={active ? styles.active : ""}
    >
      <path
        className={cn(styles.st0, styles.svgElem1)}
        d="M40.2,61.9c-0.9,0.5-0.4,1.9,0.1,2.8c1.2,2.2,1.6,4.8,1.2,7.3H4.6c-1.3-5.2,2-10.5,7.2-11.7
      c0.9-0.2,1.9-0.3,2.8-0.3c1.4-0.1,2.5-1,2.9-2.3c0.3-1.3,0.3-2.6,0.1-3.9c3.4,1.3,7.3,1.4,10.7,0.1c-0.3,1.1-0.3,2.2-0.2,3.3
      c0.2,1.1,0.9,2.1,2,2.4l6.6,1.6c0.4,0.1,0.8,0.1,1.1,0c0.5-0.4,0.6-1,0.3-1.5c0,0-0.1-0.1-0.1-0.1c-0.5-0.4-1-0.7-1.7-0.8l-5.7-1.5
      c-0.9-2.1-0.1-4.6,1.8-5.9c0.6-0.4,0.1-1.6-0.7-1.6c-0.8,0.1-1.5,0.4-2.1,0.9c-2.6,1.7-5.8,2.3-8.8,1.7c-1.8-0.4-3.5-1.2-5.1-2.2
      c-2.4-1.5-4.4-3.7-5.8-6.2c-1.3-2.6-1.6-5.6-0.7-8.3c1.2-3.3,4.1-5.6,7.1-7.4c3-1.7,6.3-3,9.1-5.2c0.7-0.4,1.6-0.3,2.2,0.1
      c5.7,2.8,9.4,8.5,9.7,14.9c0,0.9,0.2,2.1,1.1,2.2c0.6,0,1.1-0.4,1.3-1c0.1-0.6,0.1-1.2-0.1-1.8c2-0.1,3-2.5,3.4-4.4
      c0.6-2.6,1.1-5.5,0-7.9c-0.4-0.7-0.9-1.4-1.2-2.2c-0.6-1.4-0.6-3-1-4.5c-0.7-2.8-2.4-5.2-4.9-6.7c-1.1-0.6-2.3-1.2-2.7-2.4
      c-1.3-4.6-6.6-7.3-11.3-7S12.9,5.7,9.7,9.2C9.5,9.4,9.3,9.6,9.2,9.9c-0.1,0.7,0.7,1.2,1.4,1.1c0.7-0.2,1.3-0.6,1.7-1.2
      c1.9-2.1,4.3-3.6,7-4.6c2.7-1,5.7-0.8,8.2,0.5c-2.7,0.5-5.2,1.6-7.4,3.4c-0.6,0.5-1.3,1.4-0.8,2.1c0.4,0.4,1,0.5,1.5,0.3
      c0.5-0.3,0.9-0.6,1.3-1c1.6-1.5,3.7-2.3,6-2.4c1.8,0,3.2,1.7,3.4,3.5c0,0.2,0,0.5,0,0.7c0.1,0,0.1,0.1,0,0.1c-0.1,0,0,0,0,0
      c-0.2,1.5-0.8,2.9-1.8,4c-2.2,2.9-5.4,4.9-8.6,6.6c-3.2,1.7-6.6,3.3-9.4,5.6s-5.1,5.5-5.4,9.1c-0.8-0.2-1.1-1-1.3-1.8
      c-1.7-7.2-0.7-14.8,3-21.2c0.5-0.8,0.9-2.1,0-2.5c-0.9-0.4-1.5,0.5-1.9,1.2c-3.8,6.8-5,14.8-3.4,22.5c0.3,1.6,1,3.5,2.6,4
      c1.3,0.5,1.7,2,2.1,3.3c1.1,3.5,3.5,6.5,6.6,8.4c1.6,1,1.5,3.4,1.2,5.2c0,0.3-0.1,0.6-0.4,0.6c-3.7,0.6-7.8,1.6-10.2,4.6
      s-2.4,6.8-2.5,10.4C2,73.1,2.2,73.6,2.6,74c0.3,0.2,0.7,0.3,1.1,0.2h38.6c0.5,0.1,0.9-0.1,1.3-0.4c0.2-0.3,0.3-0.7,0.3-1
      c0.2-3.3-0.3-6.5-1.6-9.5C41.9,62.5,41,61.4,40.2,61.9z M28.8,20.6c1.1-0.9,2.1-1.9,2.9-3c1.1-1.3,1.8-2.9,2-4.5
      c1.3,0.6,2.5,1.5,3.4,2.7c0.2,0.3,0.5,0.7,0.6,1.1c0.6,1.2,1.1,2.4,1.3,3.7c0,0.2,0.1,0.5-0.1,0.7c-0.4-0.4-1.1-0.4-1.5,0.1
      c-0.3,0.3-0.4,0.7-0.2,1c0.2,0.4,0.5,0.7,0.8,0.9c0.7,0.5,1.4,0.9,2.1,1.4c0.7,0.5,1.1,1.3,1.2,2.1c0,0.4,0,0.8,0.3,1.1
      c0,0.5,0,0.9,0,1.4c-0.1,0-0.2,0.1-0.2,0.3c0,1.6-0.4,3.3-1.1,4.8c-0.1,0.2-0.3,0.4-0.5,0.6c0,0.1-0.2,0-0.3-0.1
      c-0.3-0.7-0.6-1.4-0.7-2.1c-1.6-5.1-5.2-9.2-10-11.5c-0.1,0-0.1-0.1-0.2-0.1C28.4,20.9,28.6,20.7,28.8,20.6z"
      ></path>
      <path
        className={cn(styles.st0, styles.svgElem2)}
        d="M31.5,12.3L31.5,12.3C31.5,12.3,31.5,12.3,31.5,12.3C31.5,12.4,31.5,12.3,31.5,12.3z"
      ></path>
      <path
        className={cn(styles.st0, styles.svgElem3)}
        d="M49.5,43.6c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0c0.3-0.1,0.6-0.3,0.7-0.5c0.1-0.1,0.1-0.3,0.2-0.4
      c0.1-0.1,0.1-0.3,0.1-0.4c-0.1-0.1-0.1-0.3-0.2-0.4c-0.3-0.5-1-0.7-1.6-0.4c-0.5,0.3-0.7,1-0.4,1.6c0,0,0.1,0.1,0.1,0.1
      C49,43.4,49.3,43.6,49.5,43.6z"
      ></path>
      <path
        className={cn(styles.st0, styles.svgElem4)}
        d="M49.3,37.1c0.1,0,0.1,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.1c0.2,0,0.4,0,0.6-0.1c0-0.1,0-0.1,0-0.2
      c0.2,0.1,0.4-0.1,0.5-0.2s0.1-0.4,0.3-0.5c0.2-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.2-0.1-0.4-0.3-0.5
      c-0.2-0.1-0.4-0.2-0.6-0.1c-0.4,0-0.7,0.1-0.9,0.4c-0.1,0.2-0.2,0.4-0.1,0.6c0,0.2,0,0.5,0.1,0.7C48.9,36.9,49.1,37.1,49.3,37.1z"
      ></path>
      <path
        className={cn(styles.st0, styles.svgElem5)}
        d="M44.9,40.2c0.1,0.1,0.2,0.1,0.3,0.1c0.3,0.1,0.6,0.1,0.9,0c0.2,0,0.4-0.1,0.4-0.3c0-0.2,0.2-0.2,0.3-0.3
      s0-0.6-0.1-0.8c-0.1-0.2-0.3-0.3-0.4-0.5c-0.2-0.1-0.5-0.1-0.8-0.1c-0.2,0-0.5,0.1-0.6,0.3c0,0.1-0.1,0.1-0.2,0.1
      c-0.1,0-0.2,0.2-0.2,0.4C44.4,39.5,44.6,39.9,44.9,40.2z"
      ></path>
      <path
        className={cn(styles.st0, styles.svgElem6)}
        d="M17.8,54.2l0.1-0.2l-0.1-0.1l0,0C17.7,53.9,17.7,54.1,17.8,54.2C17.8,54.2,17.8,54.2,17.8,54.2z"
      ></path>
      <path
        className={cn(styles.st0, styles.svgElem7)}
        d="M41.3,51.2c0.6-0.2,0.9-0.8,0.8-1.3c-0.1-0.3-0.2-0.5-0.4-0.7l-0.3-0.4c-0.2-0.4-0.6-0.7-1-0.8
      c-0.4-0.1-0.9,0-1.2,0.4C39.1,48.5,39,48.7,39,49c0,0.2,0,0.4,0.1,0.7c0.1,0.2,0.2,0.3,0.3,0.5l0.5,0.6c0.1,0.2,0.3,0.3,0.5,0.4
      c0.2,0.1,0.3,0.1,0.5,0.1C41,51.3,41.2,51.2,41.3,51.2z"
      ></path>
      <path
        className={cn(styles.st0, styles.svgElem8)}
        d="M62.5,62.3c-0.2-0.4-0.5-0.7-1-0.8c-0.5,0-0.9,0.3-1,0.8c-0.1,0.4-0.2,0.9-0.2,1.4c0,2.1,0,4.2,0,6.3
      c0,0.3-0.3,0.4-0.6,0.4c-0.3-0.1-0.5-0.2-0.7-0.4c-0.8-0.7-1.6-1.9-1-2.8c0.4-0.7,0.4-1.5,0.1-2.2c-0.3-0.7-0.7-1.4-1.2-1.9
      c-2.3-2.8-4.9-5.3-6.4-8.5c-0.7-1.4-1.1-2.9-1.8-4.2c-1.3-2.2-3.1-4.1-5.1-5.6c-0.7-0.7-1.6-1.4-2.6-1.2c-0.5,0.1-1.2,0.4-1.5,0
      c-1-1-2.1-2-3.2-2.9c-0.4-0.3-0.8-0.5-1.3-0.6c-0.5,0-1.1,0.3-1.5,0.6c-0.5,0.4-1,0.8-1.3,1.4c-0.4,0.6-0.4,1.3-0.1,1.9l2.4,3.9
      c0.2,0.3,0.4,0.7,0.2,0.9c-0.6,0.8-0.1,2,0.4,2.8c1.2,2.3,2.8,4.3,4.7,6.1c0.9,0.7,1.9,1.3,2.9,1.9c2.4,1.6,4.6,3.6,6.3,6
      c0.8,1.1,1.6,2.2,2.6,3.2c1,1,2.4,1.4,3.7,1.2c0.6-0.1,1.2,0.2,1.6,0.7c0.4,0.5,0.7,1,1.2,1.4c1.2,1,2.9,0.9,4-0.2
      c0.3-0.3,0.5-0.7,0.6-1.1c0.1-0.4,0.1-0.9,0.1-1.4v-5.6C62.7,63.1,62.6,62.7,62.5,62.3z M35.9,46l-0.4-0.6l-0.3-0.5l-0.4-0.7
      c-0.1-0.2-0.3-0.5-0.4-0.7c0-0.1-0.1-0.2-0.2-0.3c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0.1-0.1l0.7-0.6c0.2,0.1,0.4,0.3,0.6,0.5l2.2,2
      c0.1,0.1,0.2,0.2,0.2,0.3c0,0.2-0.1,0.4-0.3,0.5c-0.2,0.1-0.3,0.2-0.4,0.3c-0.2,0.3-0.5,0.4-0.8,0.5C36.2,46.3,36,46.1,35.9,46z
       M38.8,51.8c-0.6-0.3-1.2-0.7-1.7-1.2c-0.3-0.3-0.7-0.7-0.6-1.1c0.1-0.2,0.2-0.3,0.4-0.4c1.2-1,2.4-2,3.6-3c0.2-0.3,0.7-0.4,1.1-0.2
      c0.1,0.1,0.2,0.2,0.3,0.4c0.6,0.9,1,1.9,1.2,3c0,0.1,0,0.3,0,0.4c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.4-0.3,0.8-0.6,1.1
      c-0.5,0.4-1,0.5-1.6,0.5C40.1,52.2,39.4,52.1,38.8,51.8z M55.7,66.8c-0.2,0.4-0.6,0.8-1.1,0.9c-0.4,0.1-0.9,0-1.2-0.3
      c-0.4-0.3-0.7-0.6-0.9-0.9l-1.4-1.7c-1.6-2.2-3.5-4.2-5.6-5.9c-2-1.5-4.3-2.5-5.9-4.4c0.3-0.1,0.6-0.2,0.9-0.1
      c2.3,0.3,4.4-1.4,4.7-3.7c0-0.2,0-0.4,0-0.6c0-0.2,0-0.4,0.1-0.6c0.6,0.5,1,1,1.3,1.7l1.9,4.2c0.3,0.6,0.5,1.2,0.9,1.8
      c0.5,0.8,1,1.5,1.5,2.2c1.2,1.5,2.4,2.8,3.6,4.2c0.4,0.4,0.8,0.9,1.1,1.5C55.9,65.6,55.9,66.2,55.7,66.8L55.7,66.8z M55.9,66
      L55.9,66c0-0.1,0-0.2,0.1-0.2L55.9,66C56,66,55.9,66,55.9,66L55.9,66z"
      ></path>
    </svg>
  );
};

export default Aku;
