import React from "react";
import { cn } from "../../lib/helpers";
import b1 from "../../images/b1.png";
import b2 from "../../images/b2.png";
import b3 from "../../images/b3.png";
import b4 from "../../images/b4.png";
import b5 from "../../images/b5.png";
import b6 from "../../images/b6.png";

const Balicek = ({ active, width = "100px", packageType }) => {
  const packages = {
    balicek1: <img width={width} alt="Vánoční balíček" src={b1}  />,
    balicek2: <img width={width} alt="Vánoční balíček" src={b2} />,
    balicek3: <img width={width} alt="Vánoční balíček" src={b3} />,
    balicek4: <img width={width} alt="Vánoční balíček" src={b4} />,
    balicek5: <img width={width} alt="Vánoční balíček" src={b5} />,
    balicek6: <img width={width} alt="Vánoční balíček" src={b6} />,
  }
  return (packages[packageType]);
};

export default Balicek;
