import React from "react";

import Dermapen from "./dermapen";
import Aku from "./aku";
import Bio from "./bio";
import Rty from "./rty";
import Chiro from "./chiro";
import Derma from "./derma";
import Oci from "./oci";
import Hubnuti from "./hubnuti";
import Laser from "./laser";
import Lift from "./lift";
import Mezo from "./mezo";
import Vlasy from "./vlasy";
import Balicek from "./balicek";
import Psychk from "./psychk"

const renderIcon = {
  dermapen: (active, width) => <Dermapen active={active} width={width} />,
  akupunktura: (active, width) => <Aku active={active} width={width} />,
  biofibre: (active, width) => <Bio active={active} width={width} />,
  rty: (active, width) => <Rty active={active} width={width} />,
  chiropraxe: (active, width) => <Chiro active={active} width={width} />,
  dermatologie: (active, width) => <Derma active={active} width={width} />,
  oci: (active, width) => <Oci active={active} width={width} />,
  hubnutí: (active, width) => <Hubnuti active={active} width={width} />,
  laser: (active, width) => <Laser active={active} width={width} />,
  lifting: (active, width) => <Lift active={active} width={width} />,
  mezoterapie: (active, width) => <Mezo active={active} width={width} />,
  vlasy: (active, width) => <Vlasy active={active} width={width} />,
  psychk: (active, width) => <Psychk active={active} width={width} />,
  balicek1: (active, width, packageType) => <Balicek active={active} width={width} packageType={packageType} />,
  balicek2: (active, width, packageType) => <Balicek active={active} width={width} packageType={packageType} />,
  balicek3: (active, width, packageType) => <Balicek active={active} width={width} packageType={packageType} />,
  balicek4: (active, width, packageType) => <Balicek active={active} width={width} packageType={packageType} />,
  balicek5: (active, width, packageType) => <Balicek active={active} width={width} packageType={packageType} />,
  balicek6: (active, width, packageType) => <Balicek active={active} width={width} packageType={packageType} />,
};

const Icon = ({ type, active, width }) => {
  return renderIcon[type] ? renderIcon[type](active, width, type) : null;
}
export default Icon;

// width={width} className={active ? styles.active : ""}
// className={cn(styles.st0, styles.svgElem)}
