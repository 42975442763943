import React from "react";
import { cn } from "../../lib/helpers";
import * as styles from "./lift.module.css";

const Aku = ({ active, width = "100px" }) => {
  return (
    <svg
      version="1.1"
      id="Vrstva_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 65.4 78"
      width={width}
      className={active ? styles.active : ""}
    >
      <path
        className={cn(styles.st0, styles.svgElem1)}
        d="M40.4,62.3c-0.8,0.6-0.3,1.8,0.2,2.7c1.1,2,1.6,4.3,1.2,6.5c0,0.2-0.1,0.4-0.3,0.6c-0.2,0.1-0.4,0.2-0.6,0.1
	H5.6c-0.2-2.2-0.3-4.5,0.6-6.6c1.7-3.6,6.1-5,10.1-5.5c1.2-0.3,2.1-1.2,2.3-2.4c0.2-1.2,0.2-2.3-0.1-3.5c3.3,1.3,7.1,1.3,10.4,0
	c0.6,1.7-0.7,3.9,0.7,5.1c0.4,0.3,0.8,0.5,1.3,0.5l6.6,1.7c0.2,0.1,0.5,0.1,0.8,0.1c0.6-0.2,0.7-1.1,0.3-1.6c-0.5-0.5-1-0.8-1.7-0.9
	l-5.6-1.5c-0.3-1.7-0.5-3.7,0.7-5c0.6-0.5,1.2-0.9,1.9-1.3c0.7-0.4,1.1-1.1,1.1-1.9c-0.1-0.8-1.2-1.3-1.7-0.7
	c-2.8,3.2-7.1,4.7-11.3,4.1c-4.2-0.6-7.9-3-10.2-6.6C10,43.5,9.1,40,10,36.8c1-3.4,3.9-5.8,6.9-7.6c3-1.8,6.4-3.1,9.2-5.2
	c0.7-0.5,1.8-0.2,2.6,0.2c5,2.7,8.4,7.6,9.2,13.2c0,0.8,0.3,1.5,0.8,2.1c0.6,0.5,1.7,0.1,1.6-0.6c-0.1-0.7,0.9-1.1,1.5-1.5
	c0.8-0.7,1.3-1.6,1.5-2.6c0.8-2.8,1.5-6,0.3-8.7c-0.6-1.3-1.7-2.5-1.8-4c-0.1-3.8-2.1-7.2-5.3-9.3c-1.1-0.7-2.3-1.3-2.7-2.4
	c-1.3-4.3-6.1-7-10.7-6.9c-4.5,0.2-8.7,2.7-11.9,6c-0.5,0.4-0.8,1.1-0.9,1.7c0,0.7,0.9,1.3,1.4,0.8c2.2-2.2,4.7-4,7.5-5.3
	c2.9-1.2,6.2-1.4,8.9,0.2C25.5,7.3,23,8.4,21,10.1c-0.7,0.6-1.4,1.6-0.7,2.3c0.6,0.7,1.8,0.1,2.5-0.5c1.3-1.2,2.8-2.1,4.5-2.6
	c1.7-0.3,3.8,0.4,4.5,2c0.5,1.4,0.4,3-0.4,4.3c-1.7,3.4-4.9,5.8-8.2,7.7s-6.9,3.4-9.9,5.7s-5.7,5.5-5.9,9.4
	c-0.5,0.5-1.2-0.4-1.3-1.1c-0.7-3.2-0.9-6.6-0.8-9.9l0,0c0.3-0.1,0.3-0.4,0.3-0.7c0-3.8,1.2-7.4,3.3-10.6c0.6-0.8,1.1-2.2,0.2-2.7
	c-0.9-0.5-1.6,0.4-2.1,1.2c-3.7,6.4-4.7,14.1-3.5,21.3C3.6,37,4,38.2,4.6,39.2c0.6,1,1.6,1.7,2.8,1.8c0.9,4.6,3.6,8.7,7.5,11.2
	c1.7,1.1,1.6,3.5,1.2,5.5c-4,0.7-8.3,1.7-10.8,4.9c-2.1,2.8-2.2,6.5-2.3,10c0,0.6,0,1.3,0.5,1.6c0.3,0.1,0.6,0.2,0.9,0.2h38.3
	c0.4,0,0.7-0.1,1-0.3c0.1-0.2,0.2-0.4,0.2-0.7c0.4-3.3-0.1-6.7-1.4-9.8C42.2,62.8,41.2,61.7,40.4,62.3z M29.5,21.4
	c1.4-1.1,2.6-2.5,3.5-4.1c0.2-0.3,0.4-0.7,0.6-1c0.2-0.4,0.3-0.8,0.4-1.2c0-0.2,0.1-0.5,0.2-0.7c0.3-0.2,0.7-0.1,1,0.1
	c2.3,1.6,3.8,4.1,4.1,6.9c0,0.1,0.1,0.3,0.2,0.3c0.1,0.1,0.1,0.3,0,0.4c-0.1,0.1-0.3,0-0.3-0.1c-0.6-0.3-1.3,0-1.6,0.5
	c-0.2,0.5-0.1,1.1,0.4,1.4l1.7,1.2c0.4,0.3,0.8,0.6,1.1,0.9c0.4,0.5,0.7,1.2,0.8,1.9c0.2,1.1,0.2,2.1,0.1,3.2c0,0.1-0.1,0.3-0.2,0.2
	c-0.1,1.3-0.4,2.6-1,3.8c-0.1,0.3-0.4,0.6-0.7,0.4c-0.1-0.1-0.2-0.2-0.2-0.3c-0.3-0.9-0.6-1.7-0.9-2.6c-1.7-4.6-5-8.3-9.4-10.5
	c-0.1,0-0.1-0.1-0.2-0.1C29.1,21.9,29.3,21.6,29.5,21.4L29.5,21.4z"
      ></path>
      <path
        className={cn(styles.st0, styles.svgElem2)}
        d="M61.7,63.9c-0.6-0.8-1.5-1.6-2.4-1.4c-0.5,0.2-1,0.5-1.3,1c-0.3,0.5-0.8,0.8-1.3,1c-0.5,0.2-1.2-0.1-1.3-0.7
	c-0.1-0.6,0.4-0.9,0.7-1.2l2.9-2.3c0.4-0.3,0.9-0.8,0.9-1.3c-0.1-0.7-0.7-1.1-1.4-1c0,0-0.1,0-0.1,0c-0.7,0.2-1.2,0.7-1.5,1.3
	c-3.8-4.6-7.6-9.1-11.5-13.7c-0.4-0.5-0.9-1-1.4-1.3c-0.6-0.4-1.3-0.4-1.9-0.1L38,41c-0.4-0.3-0.9-0.6-1.4-0.4
	c-0.5,0.2-1.1-0.3-1.5-0.8l-1.2-1.5c-0.3-0.4-0.7-0.8-1.1-1.1c-0.5-0.3-1.1-0.2-1.5,0.2c-0.4,0.6,0.1,1.5,0.6,2.1l1.7,2
	c0.3,0.4,0.6,0.9,0.3,1.3c-0.3,0.4-0.3,1,0,1.4c0.3,0.4,0.7,0.7,0.9,1.1c0.5,0.8,0.4,1.8,1,2.5c0.3,0.5,0.9,1.1,0.5,1.5
	c-0.6,0.7,0,1.8,0.6,2.6l11.8,14c-0.2,0.6-1.1,0.9-1.4,1.5c-0.3,0.5-0.1,1.2,0.4,1.5c0.4,0.2,1,0.2,1.3-0.1l3.1-2.7
	c0.3-0.3,0.6-0.5,1-0.6c0.4-0.1,0.8,0.1,1,0.4c0.2,0.4-0.2,0.9-0.6,1.2l-1.9,1.8c-0.5,0.5-0.1,1.3,0.2,1.8c0.3,0.6,0.8,1.1,1.3,1.5
	c0.6,0.4,1.3,0.4,1.9,0l6.2-5.1c0.6-0.4,1.1-1,1.2-1.7C62.3,64.8,62.1,64.3,61.7,63.9z M38.2,47.1c0-0.1-0.1-0.1-0.1-0.2
	c0-0.1-0.1-0.1-0.2-0.1c0.1-0.3-0.3-0.4-0.3-0.7c0-0.2,0-0.3,0-0.5c0-0.2-0.1-0.3-0.3-0.3c0.1-0.4-0.1-0.8-0.3-1.1l-0.6-0.7
	c0,0,0.1,0,0.2-0.1c0-0.1,0-0.1,0-0.2c0.1-0.2,0.3-0.1,0.5,0c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.3,0.4,0.6,0.5
	c0.3,0.2,0.6,0.3,0.9,0.3c0,0.1,0,0.2,0.1,0.2c0.1,0,0.2,0.1,0.2,0.1c0.3,0.1,0.6,0.3,0.7,0.7c0,0.1,0.1,0.2,0.1,0.2
	c0.1,0,0.2,0.1,0.1,0.2l-0.6,0.5l-0.3,0.3l-0.5,0.4C38.6,47.2,38.3,47.3,38.2,47.1z M41.1,52.9c-0.2,0-0.4-0.2-0.6-0.3L39,50.8
	c-0.2-0.2-0.3-0.4-0.3-0.7c0.1-0.2,0.2-0.3,0.3-0.4l1.3-1.1l2.3-1.9c0.2-0.1,0.4-0.1,0.6,0c0.2,0.1,0.3,0.3,0.4,0.4l1.4,1.8
	c0.1,0.2,0.3,0.4,0.2,0.6c-0.1,0.1-0.1,0.2-0.2,0.3l-3.6,3C41.4,52.8,41.3,52.9,41.1,52.9z M51,64.1c-0.1,0.1-0.2,0.1-0.3,0.2
	c-0.3,0.1-0.5-0.2-0.7-0.4l-0.6-0.7c-0.2-0.2-0.3-0.5-0.3-0.8c0.1-0.3,0.5-0.4,0.8-0.6c0.5-0.4,0.7-1.1,0.5-1.7
	c-0.1-0.1-0.1-0.3-0.3-0.4c-0.3-0.3-0.8-0.4-1.2-0.1l-1.3,1c-0.1,0.1-0.2,0.1-0.3,0c-0.5-0.7-1-1.3-1.6-1.8c-0.2-0.2,0-0.5,0.2-0.7
	c0.4-0.4,1.1-0.7,1.1-1.3c0,0.1,0.2,0,0.2,0c0-0.1,0-0.2,0-0.3c-0.1-0.3-0.2-0.6-0.4-0.8c-0.2-0.2-0.5-0.3-0.8-0.3
	c-0.7,0-1.2,0.7-1.7,1.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2-0.1-0.3-0.2l-1.1-1.4c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1,0.1-0.2,0.2-0.3
	l3.5-3c0.1-0.1,0.3-0.2,0.4-0.3c0.4-0.1,0.7,0.2,0.9,0.5c2.4,3,4.9,5.9,7.4,8.9l-0.7,0.6L51,64.1z M59.8,65.3
	C59.8,65.4,59.7,65.3,59.8,65.3c-0.3,0.2-0.5,0.4-0.8,0.6l-1.4,1.2l-3.1,2.6c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.3-0.1-0.3-0.2
	l3.7-3.1l0.7-0.6l0.9-0.8c0.1-0.1,0.2-0.2,0.4-0.2C59.9,65,59.9,65.3,59.8,65.3z"
      ></path>
    </svg>
  );
};

export default Aku;
